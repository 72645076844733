<template>
  <div class="home">
    <div class="logo"></div>
    <div class="navigationBar">
      <a class="navigationItem">品牌</a>
      <a class="navigationItem float-right" :href="joinUs_url">招聘信息</a>
    </div>
    <img class="bannerCode" src="https://static.wetry.shop/wetry-web/PC/v2/bannerCode.png" width="114" alt />
    <div class="homeBigBg" ref="homeBigBg" :style="`height: ${bgHeight}px`">
    </div>
    <div class="whyChooseUs">
      <div class="featureItem" v-for="i in [1,2,3,4,5]" :key="i">
        <img :src="`https://static.wetry.shop/wetry-web/PC/v2/${i}-${i%2 === 0 ? 2 : 1}.png`" width="370" alt />
        <div class="gap"></div>
        <img :src="`https://static.wetry.shop/wetry-web/PC/v2/${i}-${i%2 === 0 ? 1 : 2}.png`" width="370" alt />
      </div>
    </div>
    <wfooter></wfooter>
    <websiteInfo></websiteInfo>
  </div>
</template>

<script>
import websiteInfo from "@/components/websiteInfo";
import wfooter from "@/components/wfooter.vue";
export default {
  name: "Home",
  components: { websiteInfo, wfooter },
  data() {
    return {
      timer: null,
      bgHeight: 0,
      joinUs_url: 'https://www.zhipin.com/gongsi/9c2240f8a89a4e400nd92dS1FA~~.html?ka=search_rcmd_company_9c2240f8a89a4e400nd92dS1FA~~_custompage'
    };
  },
  created() {
    window.addEventListener("resize", this.onSizeChange);
  },
  mounted() {
    this.changeData();
  },
  destroyed() {
    window.removeEventListener("resize", this.onSizeChange);
  },
  methods: {
    onSizeChange() {
      if (!this.timer) {
        this.timer = true;
        setTimeout(() => {
          this.changeData();
          this.timer = false;
        }, 1000);
      }
    },
    changeData() {
      let width = this.$refs.homeBigBg.offsetWidth < 960 ? 960 : this.$refs.homeBigBg.offsetWidth;
      this.bgHeight = (width * 59) / 108;
    },
  },
};
</script>

<style lang="scss" scoped>
.home {
  min-width: 960px;
  padding-top: 12px;
  padding-bottom: 100px;
}

.logo {
  width: 190px;
  height: 28px;
  margin: 28px auto;
  background: url("https://static.wetry.shop/wetry-web/PC/v2/logo.png") center center/100%
    no-repeat;
}

.navigationBar {
  width: 966px;
  padding-left: 84px;
  margin: 0 auto;
  margin-bottom: 20rpx;

  .navigationItem {
    font-size: 14px;
    font-weight: 600;
    color: #282828;
    line-height: 20px;
    &.float-right {
      float: right;
      text-decoration: none;
      font-size: 12px;
      font-weight: normal;
    }
  }
}

.homeBigBg {
  width: 100%;
  background: url("https://static.wetry.shop/wetry-web/PC/v2/banner.png") center top/100%
    no-repeat;
  padding-right: 40px;
}

.bannerCode{
  position: fixed;
  right: 40px;
  top: 45%;
}

.whyChooseUs {
  .featureItem {
    width: 960px;
    height: 480px;
    margin: 100px auto;
    display: flex;
    align-items: center;
    justify-content: center;

    .gap{
      width: 20px;
      height: 20px;
    }
  }
}
</style>
