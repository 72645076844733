import Vue from 'vue'
import App from './App.vue'
import index from '@/pages/index.vue'
import protocol from '@/pages/protocol.vue'
import Router from 'vue-router'

let router = new Router({
  mode: 'history',
  routes: [
    { path: '/', name: 'index', component: index },
    { path: '/protocol', name: 'protocol', component: protocol },
  ]
})

Vue.use(Router);

Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App),
  mounted: () => document.dispatchEvent(new Event("x-app-rendered")),
}).$mount('#app')
