<template>
  <div v-if="html" style="height: 100%; padding: 20px;" v-html="html"></div>
</template>
<script>
import request from "@/api/request";
export default {
  name: "protocol",
  data() {
    return {
      html: null,
    };
  },
  async created() {
    this.html = await request.get("/protocol20200807.html", {
      accept: "text/html, text/plain",
    });
  },
};
</script>