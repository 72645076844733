<template>
  <div id="index">
    <div class="PC">
      <Home />
    </div>
    <div class="mobile">
      <HomeMobile />
    </div>
  </div>
</template>

<script>
import Home from "@/pages/Home.vue";
import HomeMobile from "@/pages/HomeMobile.vue";

export default {
  name: "app",
  components: {
    Home,
    HomeMobile
  }
};
</script>

<style lang="scss">
@import url("../styles/utils.scss");
#index {
  height: 100%;
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  color: #2c3e50;
}
.PC {
  display: block;
}
.mobile {
  display: none;
}
// @media screen and (max-width: 430px) {
//   .PC {
//     display: none;
//   }
//   .mobile {
//     display: block;
//   }
// }
</style>
