<template>
  <div class="websiteInfo">
    <p>
      <a v-if="icp"
        href="https://beian.miit.gov.cn/#/Integrated/index"
        target="_blank"
        class="text"
        style="text-decoration: none;"
      >沪ICP备{{icp}}</a> | 上海懂一点网络科技有限公司
    </p>
    <p>
      <a  v-if="host ==='wetry.shop' || host ==='www.wetry.shop'"
        href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=31010602005186"
        target="_blank"
        class="text"
        style="text-decoration: none;"
      >
        <img
          src="https://static.wetry.shop/beian.png"
          style="width: 20px;top: 5px;position: relative;"
        />沪公网安备 31010602005186号 | 
      </a>地址：上海市浦东新区崮山路500号明悦大厦
    </p>
  </div>
</template>
<script>
export default {
  name: "websiteInfo",
  data() {
    return {
      host: ""
    };
  },
  computed: {
    icp() {
      if (this.host === "alittletechnology.com" || this.host === "www.alittletechnology.com") {
        return "19028907号-2";
      } else if(this.host === "wetry.shop" || this.host === "www.wetry.shop") {
        return "19028907号-1";
      } else if(this.host === "wanatry.com" || this.host === "www.wanatry.com") {
        return "2023018735号-2";
      } else if (this.host === "wanatry.com.cn") {
        return "2023018735号-1"
      }
      return null
    }
  },
  created() {
    console.log(window.location);
    this.host = window.location.host;
  }
};
</script>
<style lang="scss" scoped>
.websiteInfo {
  width: 100%;
  margin: 0 auto;
  text-align: center;
  font-size: 10px;
  color: rgba(51, 51, 51, 1);
  line-height: 20px;
}

@media screen and (max-width: 375px) {
  .websiteInfo {
    width: 100%;
    margin: 0 auto;
    padding: 0 0 20px 0;
    line-height: 18px;
  }
}
</style>