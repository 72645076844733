<template>
  <div class="wfooter">
    <div class="footerBlock" style="float: left">
      <div class="footerBlockTitle">WANA TRY ONLINE SHOPPING</div>
      <div class="footerItem" v-for="item in ['微信小程序', '淘宝', '抖音', '小红书']" :key="item">
        <a class="footerItemText">{{item}}</a>
        <img class="footerItemCodeImg" :src="`https://static.wetry.shop/wetry-web/PC/v2/${item}.png`" :alt="item" width="90">
      </div>
    </div>

    <div class="footerBlock" style="float: right">
      <div class="footerBlockTitle">联系我们 CONTACT US</div>
      <div class="footerItem" v-for="item in ['微信', '微博']" :key="item">
        <a class="footerItemText">{{item}}</a>
        <img class="footerItemCodeImg right" :src="`https://static.wetry.shop/wetry-web/PC/v2/${item}.png`" :alt="item" width="90">
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'wfooter'
}
</script>
<style lang="scss" scoped>
.wfooter{
  box-sizing: border-box;
  width: 960px;
  height: 232px;
  margin: 0 auto 150px;
  padding: 10px 70px;
  border-top: 1px solid #2F2F2F;
  .footerBlock{
    display: flex;
    flex-direction: column;
    .footerBlockTitle{
      padding: 32px 0;
      font-size: 16px;
      font-weight: bold;
      color: #262626;
      line-height: 19px;
    }
    .footerItem{
      margin-bottom: 16px;
      font-size: 14px;
      font-weight: 400;
      color: #262626;
      line-height: 20px;
      position: relative;

      &:last-child{
        margin-bottom: 0;
      }

      .footerItemCodeImg{
        display: none;
      }


      &:hover{
        .footerItemCodeImg{
          display: block;
          position: absolute;
          left: 92px;
          top: 0;

          &.right{
            left: 49px;
            top: 0;
          }
        }
      }
    }
  }
}
</style>