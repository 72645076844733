<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: "app",
};
</script>

<style lang="scss">
@import url("./styles/utils.scss");
#app {
  width: 100%;
  height: 100%;
  font-family: PingFangSC-Light, PingFang SC;
  color: #2c3e50;

  a{
    cursor: pointer;
  }
}
</style>
