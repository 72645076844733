<template>
  <div class="HomeMobile">
    <div class="bigbg" ref="homeBigBg">
      <div class="bigbgTitle">
        <img
          class="model"
          src="https://static.wetry.shop/wetry-web/mobile/model@2x.png"
          alt
          :height="cyHeight"
        />
        <div class="title">
          <img src="https://static.wetry.shop/wetry-web/mobile/mobile-title@2x.png" alt width="80%" />
          <p class="mv-x2" style="color: #333;">扫码开启wana try</p>
          <div class="codes">
            <div class="codeItem mr-x4">
              <img src="https://static.wetry.shop/wetry-web/newPc/小程序码@2x.png" width="85" alt />
              <p>微信小程序</p>
            </div>
            <div class="codeItem">
              <img src="https://static.wetry.shop/wetry-web/newPc/公众号@2x.png" width="85" alt />
              <p>微信公众号</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <img class="box" src="https://static.wetry.shop/wetry-web/mobile/box.png" alt width="100%" />

    <div class="whychooseus">
      <img src="https://static.wetry.shop/wetry-web/mobile/01@2x.png" width="100%" alt />
      <img src="https://static.wetry.shop/wetry-web/mobile/02@2x.png" width="100%" alt />
      <img src="https://static.wetry.shop/wetry-web/mobile/03@2x.png" width="100%" alt />
      <img src="https://static.wetry.shop/wetry-web/mobile/04@2x.png" width="100%" alt />
    </div>
    <websiteInfo></websiteInfo>
  </div>
</template>
<script>
import websiteInfo from '@/components/websiteInfo'
export default {
  name: "HomeMobile",
  components: {websiteInfo},
  data() {
    return {
      timer: null,
      bgHeight: 0,
      cyHeight: 0
    };
  },
  created() {
    window.addEventListener("resize", this.onSizeChange);
  },
  mounted() {
    this.changeData();
  },
  destroyed() {
    window.removeEventListener("resize", this.onSizeChange);
  },
  methods: {
    onSizeChange() {
      if (!this.timer) {
        this.timer = true;
        setTimeout(() => {
          this.changeData();
          this.timer = false;
        }, 1000);
      }
    },
    changeData() {
      let width = this.$refs.homeBigBg.offsetWidth;
      this.bgHeight = (width * 798) / 375;
      this.cyHeight = (width * 431) / 375;
    }
  }
};
</script>
<style lang="scss" scoped>
.HomeMobile {
  // padding-bottom: 20px;
  padding: 16px 0;
  .bigbg {
    width: 100%;
    background: url("https://static.wetry.shop/wetry-web/mobile/mobile-background@2x.png") center
      top/auto 100% no-repeat;
  }
  .bigbgTitle {
    box-sizing: border-box;
    position: relative;
    img.model {
      margin: 0 0 0 15px;
    }
    .title {
      position: relative;
      top: -130px;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      p {
        font-size: 12px;
        color: white;
      }
    }
    .codes {
      display: flex;
      align-items: center;
      justify-content: center;
      .codeItem {
        width: 85px;
        p {
          font-size: 12px;
          text-align: center;
          margin-top: 0;
          color: rgba(153, 153, 153, 1);
        }
      }
    }
  }
  .box {
    margin-top: -80px;
  }
  .whychooseus{
    background: #EEEEEE;
    margin-top: -40px;
    padding-top: 40px;
    img{
      vertical-align: bottom;
    }
  }
}
</style>